import classNames from 'classnames'
import GlobeIcon from 'public/icons/globe.svg'
import ArrowDownIcon from 'public/icons/arrow-down.svg'
import ArrowUpIcon from 'public/icons/arrow-up.svg'
import { useTranslation } from 'app/i18n/client'

interface ButtonProps {
  open: boolean
}

const ChangeLanguageButton = ({ open }: ButtonProps) => {
  const { t } = useTranslation({
    ns: 'language',
  })

  return (
    <div className="flex items-center justify-center">
      <GlobeIcon className={classNames('stroke-current text-gray')} />
      <span className={classNames('ml-2 text-sm text-gray')}>
        {t('display-name')}
      </span>
      <ArrowDownIcon
        className={classNames(
          'ml-3 fill-current text-gray',
          open ? 'hidden' : 'block',
        )}
      />
      <ArrowUpIcon
        className={classNames(
          'ml-3 fill-current text-gray',
          open ? 'block' : 'hidden',
        )}
      />
    </div>
  )
}

export default ChangeLanguageButton
