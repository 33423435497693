'use client'

import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import ChangeLanguageButton from './Button'
import ChangeLanguagePanel from './Panel'

const ChangeLanguagePopup = () => {
  return (
    <Popover.Group as="nav" className="flex space-x-10">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button className="flex items-center justify-center">
              <ChangeLanguageButton open={open} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 mt-9 transform w-60 right-0 -mr-4">
                <div className="relative bg-white px-4 py-3 pr-6 shadow-md">
                  <ChangeLanguagePanel onClose={close} />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Popover.Group>
  )
}
export default ChangeLanguagePopup
