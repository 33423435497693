'use client'
import React from 'react'

import { Disclosure, Transition } from '@headlessui/react'

import GlobeIcon from 'public/icons/globe.svg'
import ArrowDownIcon from 'public/icons/arrow-down.svg'
import ArrowUpIcon from 'public/icons/arrow-up.svg'
import { useTranslation } from 'app/i18n/client'
import ChangeLanguagePanel from 'app/components/layouts/Header/ChangeLanguagePopup/Panel'

const ChangeLanguageMenu: React.FunctionComponent<{
  onClose?: () => void
}> = ({ onClose }) => {
  const { t } = useTranslation({
    ns: 'language',
  })

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="py-5 flex items-center justify-between w-full">
            <div className="flex items-center">
              <GlobeIcon className="stroke-current text-gray-dark" />
              <span className="ml-2 text-sm">{t('display-name')}</span>
            </div>
            <div>
              {open ? (
                <ArrowUpIcon className="ml-3 fill-current text-gray-dark" />
              ) : (
                <ArrowDownIcon className="ml-3 fill-current text-gray-dark" />
              )}
            </div>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Disclosure.Panel>
              <div className="mt-2">
                <ChangeLanguagePanel onClose={onClose} />
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default ChangeLanguageMenu
