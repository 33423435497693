import Link from 'next/link'

import { Loading } from 'app/components/atoms'

import AccountIcon from 'public/icons/account.svg'
import PointIcon from 'public/icons/point.svg'

import formatToPoint from 'utils/formatToPoint'
import { useTranslation } from 'app/i18n/client'
import { useParams } from 'next/navigation'

interface AccountInfoProps {
  userName: string
  pointInfo: {
    isLoading?: boolean
    isError?: boolean
    totalPoint?: number
  }
}

const AccountInfo: React.FunctionComponent<AccountInfoProps> = ({
  userName,
  pointInfo,
}) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const { isLoading, isError, totalPoint } = pointInfo

  return (
    <div className="flex">
      <div>
        <Link
          href={`/${locale}/my-page/profile`}
          className="flex items-center space-x-2 pr-8"
        >
          <AccountIcon className="fill-current text-dark" />
          <span>{userName}</span>
        </Link>
      </div>
      <Link
        href={`/${locale}/my-page/point-information`}
        className="border-l border-gray-100 pl-8"
      >
        <div className="flex items-center space-x-2">
          <PointIcon className="stroke-current text-dark" />
          <div>{t('Possessed point')}</div>
          {isLoading ? (
            <div className="w-12 h-8 flex items-center justify-center">
              <Loading className="!text-white !opacity-60" size="sm" />
            </div>
          ) : isError ? (
            <div>N/A</div>
          ) : (
            <div className="font-lora text-primary">
              {formatToPoint(totalPoint)}
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}
export default AccountInfo
