'use client'
import Logo from 'app/components/atoms/Logo'
import { useTranslation } from 'app/i18n/client'
import Link from 'next/link'
import React from 'react'

const Footer: React.FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  const { t } = useTranslation()

  const linkList = [
    {
      title: t('Privacy Policy'),
      link: t('PRIVACY_POLICY_BY_THE_HOTEL'),
    },
    {
      title: t('Clause'),
      link: t('CLAUSE'),
    },
    {
      title: t('Company Overview'),
      link: t('COMPANY_OVERVIEW'),
    },
  ]

  return (
    <footer className={className}>
      <div className="lg:max-w-7xl mx-auto mt-24">
        <div className="pb-12 pt-8 text-sm text-gray-500 flex flex-col items-center">
          <Logo size="sm" />
          <div className="mt-9.5 flex space-x-8 lg:space-x-12.5">
            {linkList.map((option, index) => (
              <Link
                key={`footer-item-${index}`}
                href={option.link}
                className="text-gray-light"
              >
                {option.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
