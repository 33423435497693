import classNames from 'classnames'
import CheckIcon from 'public/icons/check.svg'
import { useTranslation } from 'app/i18n/client'
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation'

const ChangeLanguageButton: React.FC<{
  onClose?: () => void
}> = ({ onClose }) => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const queryParams = searchParams
    ? new URLSearchParams(Array.from(searchParams?.entries()))
    : undefined

  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const { t } = useTranslation({
    ns: 'language',
  })

  return (
    <ul>
      {['ja', 'en', 'zh-Hans', 'zh-Hant', 'ko']?.map((item) => {
        const isSelected = locale === item

        return (
          <li
            key={`lang-${item}`}
            className="py-3 flex items-center justify-between cursor-pointer"
            onClick={() => {
              router.push(
                `/${item}/${pathname
                  ?.split('/')
                  .slice(2)
                  ?.join('/')}?${queryParams?.toString()}`,
              )
              onClose && onClose()
            }}
          >
            <span
              className={classNames(
                'text-lg',
                isSelected ? '!text-primary' : 'text-gray-dark',
              )}
            >
              {t(`lang.${item}`)}
            </span>
            <CheckIcon
              className={classNames(
                'stroke-current text-primary',
                isSelected ? 'block' : 'hidden',
              )}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default ChangeLanguageButton
